import Header from '@react/shared/components/Header/Header.container';
import { populate } from '@sly/core/constants/populate';
import Footer from '@sly/frontend/react/shared/components/Footer';
import get from 'lodash/get';
import Helmet from 'next/head';
import { useRouter } from 'next/router';
import { StrictMode, useEffect, useMemo } from 'react';
import { host } from 'sly/config';
import ExploreByCommunity, {
  TabList,
} from 'sly/hubPage/components/HubDynamicContent/ExploreByEntity';
import { usePrefetch } from 'sly/services/api';
import { pageNames, useEvents } from 'sly/services/events';

import AdvisorReviews from './components/AdvisorReviews';
import CommunityReviews from './components/CommunityReviews';
import ExploreByCity from './components/ExploreByCity';
import HomePageHeader from './components/HomePageHeader';
import PersonalizedSearch from './components/PersonalizedSearch';
import SeniorLivingTools from './components/SeniorLivingTools';
import SeniorlyAgents from './components/SeniorlyAgents';
import SeniorlyFAQs from './components/SeniorlyFAQs';
import SeniorlyReasons from './components/SeniorlyReasons';
import SeniorlyReviews from './components/SeniorlyReviews';
import ShowcaseArticles from './components/ShowcaseArticles';
import ShowcaseCommunities from './components/ShowcaseCommunities';
import ShowcasePress from './components/ShowcasePress';
import VirtualAdvisorSection from './components/VirtualAdvisorSection';
import { Result } from './HomePage.types';
import Schema from './Schema';

const getObj = (obg: Result, value: string) => get(obg, `${value}`) || {};
const getArr = (obg: Result, value: string) => get(obg, `${value}`) || [];
const metaDescription =
  'Find the best assisted living and other senior living like memory care, respite care, CCRC, nursing homes, independent living and more.';

const HomePage = () => {
  const { events } = useEvents();
  const router = useRouter();
  useEffect(() => {
    events.page(pageNames.Home, {});
  }, []);

  const { requestInfo } = usePrefetch('getHomePageInfo', {
    populate: populate.homePage,
  });

  const {
    result: strapiResponse,
  }: { result: { data: Result }; hasFinished: boolean } = requestInfo;

  const result = strapiResponse?.data;

  const communitiesList: TabList = useMemo(
    () => getArr(result, 'communitiesList'),
    [result]
  );

  const seniorlyReviews = useMemo(
    () => getObj(result, 'seniorlyReviews'),
    [result]
  );

  const careCostsShowcase = useMemo(
    () => getObj(result, 'careCostsShowcase'),
    [result]
  );

  const caregiversShowcase = useMemo(
    () => getObj(result, 'caregiversShowcase'),
    [result]
  );

  const assistedLivingShowcase = useMemo(
    () => getObj(result, 'assistedLivingShowcase'),
    [result]
  );

  const rightCommunityShowcase = useMemo(
    () => getObj(result, 'rightCommunityShowcase'),
    [result]
  );

  const memoryCareShowcase = useMemo(
    () => getObj(result, 'memoryCareShowcase'),
    [result]
  );

  const advisorReviews = useMemo(
    () => getObj(result, 'advisorReviews'),
    [result]
  );

  const communityReviews = useMemo(
    () => getObj(result, 'communityReviews'),
    [result]
  );

  const FAQs = useMemo(() => getArr(result, 'FAQs'), [result]);

  const tools = useMemo(() => getObj(result, 'tools'), [result]);

  const communityReviewsSlugs = useMemo(
    () =>
      communityReviews?.quotes
        ?.map((quote: any) => quote.communitySlug)
        .join(' '),
    [communityReviews]
  );

  return (
    <StrictMode>
      <Helmet>
        <title>
          Assisted Living Cost &amp; Reviews | Senior Living Advisors | Seniorly
        </title>
        <meta name='description' content={metaDescription} />
        <link rel='canonical' href={`${host}${router.pathname}`} />
        <Schema metaDescription={metaDescription} />
      </Helmet>
      <header className='relative'>
        <Header showHeaderPhNo showHeaderAgentPhNo showSearch={false} />
      </header>
      <HomePageHeader />
      <SeniorlyReviews quotes={seniorlyReviews?.quotes || []} />
      <SeniorlyAgents />
      <AdvisorReviews quotes={advisorReviews?.quotes || []} />
      <VirtualAdvisorSection />
      <SeniorlyReasons />
      <SeniorLivingTools
        title={tools.title}
        description={tools.description}
        tools={tools.tools}
      />
      <SeniorlyFAQs FAQs={FAQs?.items} />
      <ShowcasePress />
      <ShowcaseArticles
        {...assistedLivingShowcase}
        eventProps={{
          location: `homepage - assisted living resources`,
          text: 'see more',
        }}
      />
      <ShowcaseArticles
        {...careCostsShowcase}
        eventProps={{
          location: `homepage - care costs`,
          text: 'see more',
        }}
      />
      <ShowcaseArticles
        {...memoryCareShowcase}
        eventProps={{
          location: `homepage - memory care resources`,
          text: 'see more',
        }}
      />
      <ShowcaseArticles
        {...caregiversShowcase}
        eventProps={{
          location: `homepage - caregivers resources`,
          text: 'see more',
        }}
      />
      <ShowcaseArticles
        {...rightCommunityShowcase}
        eventProps={{
          location: `homepage - find the right community`,
          text: 'see more',
        }}
      />

      <PersonalizedSearch />

      <ShowcaseCommunities />

      {communityReviewsSlugs && (
        <CommunityReviews
          quotes={communityReviews?.quotes || []}
          communitySlugs={communityReviewsSlugs}
        />
      )}
      <ExploreByCommunity
        entity='communities'
        title={communitiesList?.title}
        tabList={communitiesList?.tabList}
      />
      <ExploreByCity
        popularCities={result?.explorePopularCities}
        title='Explore senior living communities in popular cities'
      />
      <ExploreByCity
        popularCities={result?.explorePopularStates}
        title='Explore senior living communities by state'
        hideCount
      />
      <Footer className='' />
    </StrictMode>
  );
};

export default HomePage;
