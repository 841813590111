import Carousel from '@react/shared/components/Carousel';
import Image from '@react/shared/components/Image';
import Rating from '@react/shared/components/Rating';
import { assetPath } from 'sly/lib/path';
import { usePrefetch } from 'sly/services/api';

function Quote({ quote, author, date, stars, advisorName, profileImage, key }) {
  return (
    <div
      className='items-start space-y-6 rounded-xl border border-slate-lighter-90 p-6 shadow-subtle'
      key={key}
    >
      <div className='flex gap-4 border-b pb-6'>
        <Image
          src={assetPath(`uploads/${profileImage}`)}
          alt={advisorName}
          className='h-12 w-12 rounded-full object-cover'
          layout='raw'
          width={48}
          height={48}
        />
        <div className='flex flex-col items-start justify-start gap-1'>
          <p className='font-b-m overflow-ellipsis font-medium'>
            {advisorName}
          </p>
          <p className='font-b-m overflow-ellipses'>Seniorly Local Advisor</p>
        </div>
      </div>
      <div className='space-y-4'>
        <Rating value={stars || 5} color='primary' variation='base' />
        <p className='font-b-m'>{quote}</p>
        <div className='flex justify-between'>
          <p className='font-b-m text-slate-lighter-40'>{author}</p>
          {date && (
            <p className='font-b-m hidden text-slate-lighter-40 xl:block'>
              {date}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

const AdvisorReviews = ({ quotes }) => {
  const {
    requestInfo: { normalized },
  } = usePrefetch('getAgentsNew', {
    'filter[slug]': quotes.map(({ advisorSlug }) => advisorSlug).join(','),
    include: 'address,gallery,gallery.images',
    'page-size': quotes.length,
  });

  if (!normalized || !normalized?.length || !quotes.length) return <></>;

  return (
    <section className='mx-8 mt-6 space-y-6'>
      <h3 className='font-t-m mx-auto max-w-sm text-center'>
        Discover the difference that Seniorly advisors make
      </h3>
      <Carousel
        itemsQty={quotes.length}
        className='mx-auto max-w-5xl'
        buttonPosition='sides'
        innerClassName='lg:gap-6'
        eventProps={{ location: 'homepage - agent reviews' }}
        offset
      >
        {quotes?.map(({ quote, author, stars, advisorSlug, date }) => {
          const advisor = normalized.find(({ id }) => id === advisorSlug);
          if (
            !advisor ||
            !advisor?.info?.displayName ||
            !advisor?.gallery?.images?.[0]?.path
          )
            return null;
          return (
            <Quote
              key={quote + advisorSlug}
              quote={quote}
              author={author}
              stars={stars || 5}
              advisorName={advisor?.info?.displayName}
              profileImage={advisor?.gallery?.images?.[0]?.path}
              date={date}
            />
          );
        })}
      </Carousel>
    </section>
  );
};

export default AdvisorReviews;
